<template>
  <div>
    <v-container fluid>
      <v-row class="mb-3">
        <v-col lg="8" offset="2">
          <v-card-title>
            <h2>Accounting Fix</h2>
          </v-card-title>
          <v-btn @click="runAccountingFix()" class="mb-5"
            >Run Accounting Fix</v-btn
          >
          <!---->
          <v-card max-height="500px" height="500px" style="overflow: scroll">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="mb-5">
                  <h4>Status Logs:</h4>
                  <p v-for="message in message" :key="message">{{ message }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {
  runAccountingFix,
  getBankTransactions,
  getJournalEntries,
  getChartOfAccounts,
  getYearMonthDocs,
  getBankAccounts,
  getInvoices,
} from "../data/initialise_accounting_totals";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";

export default {
  name: "AccountingFix",
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      promises: [],
      year_month_docs: null,
      journal_entries: [],
      chart_of_accounts: [],
      bank_accounts: [],
      invoices: [],
      message: [],
    };
  },
  created() {
    this.getBankTransactions();
    this.getJournalEntries();
    this.getChartOfAccounts();
    this.getYearMonthDocs();
    this.getBankAccounts();
    this.getInvoices();
  },
  methods: {
    runAccountingFix,
    getBankTransactions,
    getJournalEntries,
    getChartOfAccounts,
    getYearMonthDocs,
    getBankAccounts,
    getInvoices,
  },
};
</script>
