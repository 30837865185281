<template>
  <div>
    <v-container fluid class="px-10">
      <!--Date picker-->
      <v-row class="mt-5">
        <v-col lg="2" offset="2">
          <v-select
            v-model="current_period"
            label="Select Period"
            item-text="text"
            item-value="value"
            @change="getAccountingTotals(current_period)"
            dense
            outlined
            :items="accounting_periods"
          ></v-select>
        </v-col>
        <!--Year to date-->
        <v-col lg="2">
          <v-select
            v-model.lazy="showYearToDate"
            :items="[
              { text: 'No', value: false },
              {
                text: 'Yes',
                value: true,
              },
            ]"
            label="Show Year To Date"
            item-text="text"
            item-value="value"
            dense
            outlined
          >
          </v-select>
        </v-col>
        <!--Export as PDF-->
        <v-col lg="2" offset-lg="2">
          <v-btn text outlined width="100%" @click="export_modal = true"
            >Export PDF <v-icon right>mdi-download</v-icon></v-btn
          >
          <IncomeStatementExportModal
            :months="accounting_periods"
            :export_modal="export_modal"
            @closeModal="export_modal = false"
          />
        </v-col>
      </v-row>
      <!--New entries alert-->
      <v-row
        ><v-col lg="8" offset="2">
          <v-alert
            dense
            type="info"
            text
            transition="scale-transition"
            :value="newEntriesAlert"
            ><v-row align="center" no-gutters>
              <v-col class="grow">
                New entries have been added for this period
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="shrink">
                <!--refresh button-->
                <v-btn
                  color="info"
                  outlined
                  @click="
                    getAccountingTotals(current_period);
                    newEntriesAlert = false;
                  "
                  ><v-icon left small>mdi-refresh</v-icon> Refresh
                </v-btn>
              </v-col>
            </v-row></v-alert
          >
        </v-col></v-row
      >
      <!---->
      <v-row class="pa-3">
        <v-col lg="8" offset="2">
          <v-sheet color="white sheet" elevation="2" width="100%">
            <v-col>
              <v-list flat>
                <!--Header-->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h4 font-weight-medium mb-3">
                      Income Statement
                    </v-list-item-title>
                    <!--Company Name-->
                    <p class="mb-3">
                      {{ company_legal_name }}
                      <span v-if="company_trading_as"
                        >T/A {{ company_trading_as }}</span
                      >
                    </p>
                    <p class="mb-3" v-if="company_reg_number">
                      Reg No: {{ company_reg_number }}
                    </p>
                    <!--Statement Date-->
                    <p>
                      For the month of
                      {{
                        new Date(current_period).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                        })
                      }}
                    </p>
                    <p>
                      For the fiscal year starting
                      {{
                        new Date(financialYearStart).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "long",
                          }
                        )
                      }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-row>
                  <v-col lg="12" v-if="!loading">
                    <v-list-item
                      three-line
                      v-for="group in groups"
                      :key="group.name"
                    >
                      <v-list-item-content v-if="group.isTotal === false">
                        <!--Account group main header-->
                        <!--Period Header-->
                        <div v-if="group.name === 'Revenue'" class="mb-3">
                          <p class="text-right font-weight-light">
                            <span :class="addYearToDateCSS('yearToDateSpan')">
                              {{
                                new Date(current_period).toLocaleDateString(
                                  "en-GB",
                                  {
                                    year: "numeric",
                                    month: "short",
                                  }
                                )
                              }}
                            </span>
                            <!--Year to date header-->
                            <span v-if="showYearToDate" class="yearToDateSpan"
                              >Year To Date</span
                            >
                          </p>
                          <v-divider></v-divider>
                        </div>

                        <v-list-item-title
                          class="text-h6 font-weight-medium mb-3"
                        >
                          {{ group.name }}
                        </v-list-item-title>
                        <!--Group total Header-->
                        <div class="my-2">
                          <p class="font-weight-bold">
                            {{ `Total ${group.name}` }}
                            <!--Group total amounts (year to date)-->
                            <span
                              class="float-right yearToDateSpan"
                              v-if="showYearToDate"
                            >
                              {{
                                formatAsCurrency(
                                  "R",
                                  groupTotal(group.name, "YTD_total")
                                )
                              }}
                            </span>
                            <!--Group total amounts (current period)-->
                            <span :class="addYearToDateCSS('float-right')">
                              {{
                                formatAsCurrency(
                                  "R",
                                  groupTotal(group.name, "monthly_total")
                                )
                              }}
                            </span>
                          </p>
                          <v-divider></v-divider>
                        </div>
                        <div
                          class="text--primary"
                          v-for="account in accounting_totals
                            .filter(
                              (i) =>
                                i.account_sub_type === group.name ||
                                i.account_type === group.name
                            )
                            .sort((x, y) => {
                              if (x.account_name < y.account_name) {
                                return -1;
                              }
                              if (x.account_name > y.account_name) {
                                return 1;
                              }
                              return 0;
                            })"
                          :key="account.account_id"
                        >
                          <div class="mt-2">
                            <p>
                              <!--Name of each account related to the group-->
                              <span>{{ account.account_name }}</span>
                              <!--Year to date Amount-->
                              <!--Amount with link to ledger report-->
                              <span
                                class="float-right yearToDateSpan"
                                v-if="showYearToDate"
                              >
                                {{ formatAsCurrency("R", account.YTD_total) }}
                              </span>
                              <!--End of year to date amount-->
                              <!--Amount with link to ledger report-->
                              <span
                                :class="addYearToDateCSS('float-right')"
                                v-if="account.monthly_total !== 0"
                              >
                                <a
                                  style="text-decoration: none"
                                  @click="toggleViewEntries(account.account_id)"
                                  >{{
                                    formatAsCurrency("R", account.monthly_total)
                                  }}
                                </a>
                              </span>
                              <span
                                v-else
                                :class="addYearToDateCSS('float-right')"
                                >-
                              </span>
                            </p>
                          </div>
                          <IncomeStatementJournalEntryScroller
                            class="sheet"
                            :account="account"
                            :current_period="current_period"
                            :view_journal_entries="view_journal_entries"
                            v-if="view_journal_entries === account.account_id"
                          />
                          <v-divider></v-divider>
                        </div>
                      </v-list-item-content>
                      <!-- Profit calculations -->
                      <v-list-item-content v-else>
                        <div>
                          <p class="font-weight-bold mb-3">
                            <span class="text-h6">
                              {{ group.name }}
                            </span>
                            <!--Proft totals (year to date)-->
                            <span
                              class="float-right yearToDateSpan"
                              v-if="
                                showYearToDate &&
                                profitCalculation(group.name, 'YTD_total') <= 0
                              "
                            >
                              ({{
                                formatAsCurrency(
                                  "R",
                                  Math.abs(
                                    profitCalculation(group.name, "YTD_total")
                                  )
                                )
                              }})
                            </span>
                            <!--Profit totals if negative number (year to date)-->
                            <span
                              class="float-right yearToDateSpan"
                              v-else-if="
                                showYearToDate &&
                                profitCalculation(group.name, 'YTD_total') > 0
                              "
                            >
                              {{
                                formatAsCurrency(
                                  "R",
                                  profitCalculation(group.name, "YTD_total")
                                )
                              }}
                            </span>
                            <!--Proft totals (current period)-->
                            <span
                              :class="
                                addYearToDateCSS('float-right yearToDateSpan')
                              "
                              v-if="
                                profitCalculation(group.name, 'monthly_total') <
                                0
                              "
                            >
                              ({{
                                formatAsCurrency(
                                  "R",
                                  Math.abs(
                                    profitCalculation(
                                      group.name,
                                      "monthly_total"
                                    )
                                  )
                                )
                              }})
                            </span>
                            <!--Profit totals if negative number (current period)-->
                            <span
                              :class="
                                addYearToDateCSS('float-right yearToDateSpan')
                              "
                              v-else
                            >
                              {{
                                formatAsCurrency(
                                  "R",
                                  profitCalculation(group.name, "monthly_total")
                                )
                              }}
                            </span>
                          </p>
                          <v-divider></v-divider>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!--Skeleton loader-->
                  <v-skeleton-loader
                    v-else
                    max-width="100%"
                    max-height="600px"
                    height="600px"
                    width="100%"
                    type="table, table-heading, table-thead, table-tbody"
                  ></v-skeleton-loader>
                </v-row>
              </v-list>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import { formatAsCurrency } from "../../../composables/external";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
export default {
  name: "IncomeStatement",
  mixins: [mixin_CompanyProfile],
  components: {
    IncomeStatementJournalEntryScroller: () =>
      import("../components/IncomeStatementJournalEntryScroller.vue"),
    IncomeStatementExportModal: () =>
      import("../components/Modals/IncomeStatementExportModal.vue"),
  },
  data() {
    return {
      current_period: new Date().toISOString().slice(0, 7),
      financial_year_start: null,
      accounting_periods: [],
      showYearToDate: false,
      groups: [
        { name: "Revenue", isTotal: false },
        { name: "Cost Of Sales", isTotal: false },
        { name: "Gross Profit", isTotal: true },
        { name: "Operating Expense", isTotal: false },
        { name: "Net Profit", isTotal: true },
      ],
      accounting_totals: [],
      accounting_totals_with_YTD: [],
      year_to_date_totals: [],
      loading: false,
      showMe: false,
      view_journal_entries: false,
      newEntriesAlert: false,
      export_modal: false,
    };
  },
  created() {
    this.getAccountingTotals(this.current_period);
    this.getAccountingPeriods();
    this.addYearToDateCSS();
  },
  beforeUpdate() {
    this.listenForNewEntries();
  },
  // Triggers the Year To Date database request when the select v-model changes
  watch: {
    showYearToDate: function (newValue) {
      if (newValue === true) {
        this.showYearToDate = newValue;
        this.view_journal_entries = false;
        this.getYearToDateTotals();
      } else {
        this.showYearToDate = newValue;
      }
    },
    current_period: function (newValue) {
      if (this.showYearToDate === true) {
        this.current_period = newValue;
        this.view_journal_entries = false;
        this.loading = true;
        this.getYearToDateTotals();
      } else {
        this.current_period = newValue;
      }
    },
  },
  computed: {
    financialYearStart() {
      // 1. Determine the first month of the finacial year
      const start_month = this.fiscal_year_start + 1;
      // 2. Determine month of current period
      const current_period_month = this.current_period.slice(
        5,
        this.current_period.length
      );
      const current_period_year = this.current_period.slice(0, 4);
      // 3. If the currnt_period month is smaller than start_month
      //    minus one from the current_period_year
      if (parseInt(current_period_month) < start_month) {
        return `${parseInt(current_period_year - 1)}-${start_month
          .toString()
          .padStart(2, "0")}`;
      } else {
        return `${parseInt(current_period_year)}-${start_month
          .toString()
          .padStart(2, "0")}`;
      }
    },
  },
  methods: {
    formatAsCurrency,
    toggleViewEntries(account_id) {
      if (this.view_journal_entries == account_id) {
        this.view_journal_entries = false;
      } else {
        this.view_journal_entries = account_id;
      }
    },
    addYearToDateCSS(classes) {
      return this.showYearToDate ? `${classes} yearToDate` : classes;
    },
    //
    getAccountingPeriods() {
      const docRef = db
        .collection("accounting_totals")
        .where("id", "<=", this.current_period)
        .orderBy("id", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          this.accounting_periods = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data().id;
            this.accounting_periods.push({
              text: new Date(data).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
              }),
              value: data,
            });
          });
        })
        .catch((error) => error);
    },
    //
    getAccountingTotals(current_period) {
      const docRef = db
        .collection("accounting_totals")
        .doc(current_period)
        .collection("ledger_accounts")
        .where("financial_statement", "==", "Income Statement")
        .where("monthly_total", ">", 0);
      docRef
        .get()
        .then((querySnapshot) => {
          this.accounting_totals = [];
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), YTD_total: 0 };
            this.accounting_totals.push(data);
          });
        })
        .catch((error) => error);
    },
    //
    getYearToDateTotals() {
      this.loading = true;
      if (!this.showYearToDate) return;
      const ledgerTotals = db
        .collectionGroup("ledger_accounts")
        .where("financial_statement", "==", "Income Statement")
        .where("month", "<=", this.current_period)
        .where("month", ">=", this.financialYearStart);
      ledgerTotals
        .get()
        .then((querySnapshot) => {
          this.year_to_date_totals = [];
          querySnapshot.forEach((doc) => {
            const data = {
              account_id: doc.data().account_id,
              account_name: doc.data().account_name,
              account_sub_type: doc.data().account_sub_type,
              monthly_total: doc.data().monthly_total,
              month: doc.data().month,
              YTD_total: 0,
            };
            this.year_to_date_totals.push(data);
          });
        })
        .then(() => {
          this.populateYearToDateAmounts();
        })
        .catch((error) => error);
    },
    //
    populateYearToDateAmounts() {
      // 1. Remove zero value months
      const nonZeroValueMonths = this.year_to_date_totals.filter((month) => {
        return month.monthly_total !== 0;
      });
      // 2. SUM the year to date amounts for "current_period" ledgers
      this.accounting_totals.forEach((CP_ledger) => {
        nonZeroValueMonths.forEach((YTD_ledger) => {
          if (CP_ledger.account_id === YTD_ledger.account_id) {
            CP_ledger.YTD_total += YTD_ledger.monthly_total;
          }
        });
      });
      // 3. Return all ledgers that aren't present in
      //    the current_period array
      const nonCommonLedgers = nonZeroValueMonths.filter((ledger) => {
        return !this.accounting_totals.some(
          (item) => item.account_id === ledger.account_id
        );
      });
      // 4. Then add these ledgers to their own array as objects
      const accountIdArray = [];
      nonCommonLedgers.forEach((ledger) => {
        const ledgerObj = {
          account_id: ledger.account_id,
          account_name: ledger.account_name,
          account_sub_type: ledger.account_sub_type,
        };
        if (!accountIdArray.includes(ledgerObj.account_id)) {
          accountIdArray.push(ledgerObj);
        }
      });
      // 5. Remove duplicate objects from array
      const uniqueSet = new Set();
      const uniqueIDArray = accountIdArray.filter((ledger) => {
        const duplicate = uniqueSet.has(ledger.account_id);
        uniqueSet.add(ledger.account_id);
        return !duplicate;
      });

      // 6. Identify all objects in nonCommonLedger with same account_ids
      //    Calculate YTD totals for each account
      let YTDTotalsArray = [];
      uniqueIDArray.forEach((id) => {
        let idArray = nonCommonLedgers.filter((ledger) => {
          return ledger.account_id === id.account_id;
        });
        let total = idArray.reduce((total, ledger) => {
          return total + ledger.monthly_total;
        }, 0);
        YTDTotalsArray.push(total);
      });
      // 7. Create new ledger objects and sum the "monthly_total"s
      let YTDLedgerArray = [];
      YTDTotalsArray.forEach((total) => {
        let ledgerOBJ = {};
        ledgerOBJ.YTD_total = total;
        ledgerOBJ.monthly_total = 0;
        ledgerOBJ.month = this.current_period;
        YTDLedgerArray.push(ledgerOBJ);
      });
      // 8. Then combine the YTD_totals and ledger objects properties so
      //    you can use them to populate the UI
      uniqueIDArray.forEach((id, i) => {
        YTDLedgerArray.forEach((obj, index) => {
          if (index === i) {
            obj = { ...obj, ...id };
            this.accounting_totals.push(obj);
          } else {
            return;
          }
        });
      });
      this.loading = false;
      return this.accounting_totals;
    },
    //
    groupTotal(name, value) {
      return this.accounting_totals
        .filter(
          (account) =>
            account.account_sub_type === name || account.account_type === name
        )
        .reduce((total, item) => {
          return total + item[value];
        }, 0);
    },
    //
    profitCalculation(name, value) {
      const totalRevenue = this.accounting_totals
        .filter((account) => account.account_type === "Revenue")
        .reduce((total, item) => {
          return total + item[value];
        }, 0);

      const totalCostOfSales = this.accounting_totals
        .filter((account) => account.account_sub_type === "Cost Of Sales")
        .reduce((total, item) => {
          return total + item[value];
        }, 0);

      const grossProfit = totalRevenue - totalCostOfSales;

      const totalOperatingExpenses = this.accounting_totals
        .filter((account) => account.account_sub_type === "Operating Expense")
        .reduce((total, item) => {
          return total + item[value];
        }, 0);

      const netProfit = grossProfit - totalOperatingExpenses;

      return name === "Gross Profit" ? grossProfit : netProfit;
    },
    // Test if new entries have been added
    listenForNewEntries() {
      const docRef = db
        .collection("accounting_totals")
        .doc(this.current_period)
        .collection("ledger_accounts");

      docRef.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            this.newEntriesAlert = true;
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.yearToDateSpan {
  display: inline-block;
  width: 160px;
  text-align: right;
}
.sheet {
  font-size: 0.85em;
}
.yearToDate {
  margin-right: 50px;
}
</style>