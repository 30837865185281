import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { firestoreDocProps } from "../../../composables/external"

export const getBankTransactions = function () {
    const bankRef = db.collection("bank_transactions")
    bankRef.onSnapshot((querySnapshot) => {
        this.bank_transactions = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.bank_transactions.push(data);
        });
    })
}

export const getJournalEntries = function () {
    const bankRef = db.collection("general_journal_entries")
    bankRef.onSnapshot((querySnapshot) => {
        this.journal_entries = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.journal_entries.push(data);
        });
    })
}

export const getChartOfAccounts = function () {
    const accountRef = db.collection("chart_of_accounts")

    accountRef.onSnapshot((querySnapshot) => {
        this.chart_of_accounts = []
        querySnapshot.forEach((doc) => {
            const data = {
                ...firestoreDocProps([
                    "account_id",
                    "account_name",
                    "account_type",
                    "account_sub_type",
                    "financial_statement",
                ], doc.data()),
                is_contra: false,
                monthly_total: 0,
                parent_account: (doc.data().parent_account) ? doc.data().parent_account.account_id : null
            }
            this.chart_of_accounts.push(data)
        })
    })
}

export const getYearMonthDocs = function () {
    const docRef = db.collection("accounting_totals")
    docRef.onSnapshot((querySnapshot) => {
        this.year_month_docs = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            this.year_month_docs.push(data)
        })
    })
}

export const getBankAccounts = function () {
    const accountArray = ["bank_accounts", "speed_points"]
    accountArray.forEach((account) => {
        const docRef = db.collection(account)
        docRef
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    this.bank_accounts.push(data)
                })
            })
            .catch((error) => error)
    })
}

export const getInvoices = function () {
    const invoiceRef = db.collection("invoices")
    invoiceRef.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                this.invoices.push(data)
            })
        }).catch((error) => error)
}
/*
    This code tests if a bank transacion matches a
    "month" document in "accounting_tables" collection.
        If there is no matching document - the document is added to the collection
*/
export const runAccountingFix = function () {
    const batch = db.batch()
    // 1. Remove all existing "year-month" documents
    const resetAccountingTotals = () => {
        this.year_month_docs.forEach((doc) => {
            batch.delete(db.doc(`accounting_totals/${doc.id}`))
        })
        this.promises.push(
            batch.commit()
                .then(() => {
                    this.message.push("1. Promise - Docs deleted")
                })
                .catch((error) => error)
        )
    }
    // 2. Create all the “year-month” documents in “accounting_totals” collection
    const initialiseMonths = () => {
        function addMonths(date, months) {
            let d = date.getDate();
            date.setMonth(date.getMonth() + +months);
            if (date.getDate() != d) {
                date.setDate(0);
            }
            return date;
        }
        const batch = db.batch()
        /* 
            Add 2 months ahead of the current month to prevent the scenario
            in which a bank transaction is added to the system and there
            is no "Accounting Totals" sub-collection to record it in
        */
        const endDate = addMonths(new Date(), 2).toISOString().slice(0, 7)
        const startDate = new Date(this.first_fiscal_year, this.fiscal_year_start + 1).toISOString().slice(0, 7)
        let newMonth = startDate
        for (let i = 0; newMonth < endDate; i++) {
            newMonth = addMonths(new Date(startDate), i).toISOString().slice(0, 7)
            //  Add date as doc in "Accounting Totals"
            batch.set(db.doc(`accounting_totals/${newMonth}`), { id: newMonth })
        }
        this.promises.push(
            batch.commit()
                .then(() => {
                    this.message.push("2. Promise - 'year-month' docs added")
                })
                .catch((error) => error)
        )
    }
    // 3. Add the chart of accounts to each month document
    const addLedgersToMonth = () => {
        this.year_month_docs.forEach((doc) => {
            const batch = db.batch()
            this.chart_of_accounts.forEach((account) => {
                const docRef =
                    db.doc(`accounting_totals/${doc.id}/ledger_accounts/${account.account_id}`)
                batch.set(docRef, { ...account, month: doc.id })
            })
            this.promises.push(
                batch.commit()
                    .then(() => {
                        this.message.push(`3. Promise - Ledgers added to ${doc.id}`)
                    })
                    .catch((error) => error)
            )
        })
    }
    // 4. Add monthly totals for unallocated bank transactions
    const populateUnallocatedBankTransactions = (type) => {
        //  Get all bank transactions
        const unallocatedTransactions = this.bank_transactions.filter((transaction) => {
            return transaction.transaction_type === type && transaction.transaction_allocation_status == "No"
        })
        //  Isolate Unallocated transactions per month
        const batch = db.batch()
        this.year_month_docs.forEach((doc) => {
            const unallocatedTransactionsPerMonth = unallocatedTransactions.filter((i) => {
                return i.transaction_date.slice(0, -3) === doc.id
            })
            //  Sum values for each month
            const totalValuePerMonth = unallocatedTransactionsPerMonth.reduce((total, item) => {
                return total + Math.abs(item.transaction_amount);
            }, 0).toFixed(4)
            //  Evaluate Type
            if (type === "Cash In") {
                // Increase "Unallocated Bank Credits" ledger for each month
                const docRef =
                    db.collection("accounting_totals").doc(doc.id)
                        .collection("ledger_accounts").doc("RSAgQsASdXZKhQRwMZUz")
                batch.update(docRef, { monthly_total: firebase.firestore.FieldValue.increment(totalValuePerMonth) })

            } else if (type === "Cash Out") {
                // Increase "Unallocated Bank Debits" ledger for each month
                const docRef =
                    db.collection("accounting_totals").doc(doc.id)
                        .collection("ledger_accounts").doc("44UTKB7Og5aZLcOvaSOl")
                batch.update(docRef, { monthly_total: firebase.firestore.FieldValue.increment(totalValuePerMonth) })
            } else {
                console.log("Error")
            }
        })
        this.promises.push(
            batch.commit()
                .then(() => {
                    this.message.push("4. Promise - Unallocated transactions added to suspense accounts")
                })
                .catch((error) => error)
        )
    }
    // 5. Add monthly totals for each allocated transaction
    const populateAllocatedTransactions = () => {
        //  break up all the line items into an array of objects
        //  We have an array of deeply nested objects
        //  We want to loop through the array, push each line item to a new array, and flatten the object
        const obj_array = [];
        this.journal_entries.forEach((entry) => {
            entry.line_items.forEach((item) => {
                obj_array.push({
                    entry_amount: item.entry_amount,
                    account_name: item.ledger_account.account_name,
                    account_id: item.ledger_account.account_id,
                    journal_entry_number: entry.journal_entry_number,
                    journal_entry_date: entry.journal_entry_date,
                })
            });
        });
        //  Identify all transactions that belong to a date
        this.year_month_docs.forEach((doc) => {
            const batch = db.batch()
            //  Identify all transactions that belong to each ledger account
            this.chart_of_accounts.forEach((account) => {
                const related_entries = obj_array.filter((entry) => {
                    return entry.journal_entry_date.slice(0, -3) === doc.id && entry.account_id === account.account_id
                })
                // sum values for each account per month
                const totalValuePerMonth = related_entries.reduce((total, item) => {
                    return total + Math.abs(item.entry_amount);
                }, 0).toFixed(4)
                // Update totals in each account for each month
                const docRef =
                    db.collection("accounting_totals").doc(doc.id)
                        .collection("ledger_accounts").doc(account.account_id)
                batch.update(docRef, { monthly_total: firebase.firestore.FieldValue.increment(totalValuePerMonth) })
            })
            this.promises.push(
                batch.commit()
                    .then(() => {
                        this.message.push(`5. Promise - Ledger amounts updated for ${doc.id}`)
                    })
                    .catch((error) => error)
            )
        })
    }
    // 6. Populate Bank Account ledger with closing balance of each month
    const setBankAccountValues = () => {
        const monthlyTotalsArray = []
        let closingBalancePerMonth = []
        // For each bank account
        this.bank_accounts.forEach((account) => {
            const batch = db.batch()
            // identify the total sum of transactions per month
            this.year_month_docs.forEach((month) => {
                const relatedTransactions = this.bank_transactions.filter((transaction) => {
                    return transaction.bank_account_id === account.bank_account_id && transaction.transaction_date.slice(0, -3) === month.id
                })
                const transactionValuesPerMonth = relatedTransactions.reduce((total, item) => {
                    if (item.transaction_type === "Cash Out") {
                        item.transaction_amount = -Math.abs(item.transaction_amount)
                    }
                    return total + item.transaction_amount
                }, 0)
                monthlyTotalsArray.push(transactionValuesPerMonth)

                closingBalancePerMonth = monthlyTotalsArray.reduce((total, item) => {
                    return total + item
                }, 0).toFixed(4)
                // Update bank account ledger with closing balance
                const docRef =
                    db.collection("accounting_totals").doc(month.id)
                        .collection("ledger_accounts").doc(account.account_id)
                batch.update(docRef, { monthly_total: firebase.firestore.FieldValue.increment(closingBalancePerMonth) })
            })
            this.promises.push(
                batch.commit()
                    .then(() => {
                        this.message.push(`6. Promise - bank closing balance added for ${account.bank_account_name}`)
                    })
                    .catch((error) => error)
            )
        })
    }
    // 7.  Sum invoice totals and add as revenue for each month
    //     Increase accounts receivable
    const setRevenue = () => {
        const batch = db.batch()
        // Calculate revenue for each month
        this.year_month_docs.forEach((month) => {
            const relatedInvoices = this.invoices.filter((el) => {
                return el.invoice_issue_date.slice(0, -3) === month.id;
            })
            const monthlyInvoiceValue = relatedInvoices.reduce((total, item) => {
                return total + item.invoice_total
            }, 0).toFixed(4)
            // Update "year-month" revenue in sub-collection
            const revenueRef =
                db.doc(`accounting_totals/${month.id}/ledger_accounts/qQGkDkuO9msttN4IFB1z`)
            batch.update(revenueRef, { monthly_total: monthlyInvoiceValue })

            // Calculate ACCOUNTS RECEIVABLE
            const monthlyAccountsReceivable = relatedInvoices.reduce((total, item) => {
                return total + item.invoice_amount_due
            }, 0).toFixed(4)
            // Update "year-month" revenue in sub-collection
            const receivablesRef = db.doc(`accounting_totals/${month.id}/ledger_accounts/Zb7fNd7tfFmOntoUcUUt`)
            batch.update(receivablesRef, { monthly_total: monthlyAccountsReceivable })
        })
        this.promises.push(
            batch.commit()
                .then(() => {
                    this.message.push(`7. Promise - Revenue updated`)
                })
                .catch((error) => error)
        )
    }

    if (this.year_month_docs.length > 0) {
        resetAccountingTotals()
    }
    initialiseMonths()
    addLedgersToMonth()
    populateUnallocatedBankTransactions("Cash Out")
    populateUnallocatedBankTransactions("Cash In")
    populateAllocatedTransactions()
    setBankAccountValues()
    setRevenue()
    this.promises.reduce((accumulatorPromise, nextPromise) => {
        return accumulatorPromise.then(() => {
            return nextPromise;
        });
    }, Promise.resolve());
}