<template>
  <div><ChartOfAccountsTable /></div>
</template>
<script>
export default {
  name: "ChartOfAccounts",
  components: {
    ChartOfAccountsTable: () => import("../components/ChartOfAccountsTable"),
  },
  data() {
    return {};
  },
};
</script>
