<template>
  <div>
    <v-container fluid class="px-10">
      <!--Date Range Picker-->
      <v-row class="mt-5">
        <v-col lg="2" md="2" sm="2" offset="2">
          <v-select
            v-model="start_month"
            :items="months"
            outlined
            dense
            label="Start Month"
            clearable
            @click:clear="
              start_month = null;
              end_month = null;
            "
          ></v-select>
        </v-col>
        <v-col lg="2" md="2" sm="2">
          <v-select
            v-model="end_month"
            :items="months"
            outlined
            dense
            label="End Month"
            clearable
            @click:clear="end_month = null"
            :disabled="!start_month"
            @change="
              invertDates();
              getLedgerAccounts(start_month, end_month);
            "
          ></v-select>
        </v-col>
        <!--Export as PDF-->
        <v-col lg="2" offset-lg="2">
          <v-btn text outlined width="100%"
            >Export PDF <v-icon right>mdi-download</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <!--New entries alert-->
      <v-row
        ><v-col lg="8" offset="2">
          <v-alert
            dense
            type="info"
            text
            transition="scale-transition"
            :value="newEntriesAlert"
            ><v-row align="center" no-gutters>
              <v-col class="grow">
                New entries have been added for this period
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="shrink">
                <!--refresh button-->
                <v-btn color="info" outlined
                  ><v-icon left small>mdi-refresh</v-icon> Refresh
                </v-btn>
              </v-col>
            </v-row></v-alert
          >
        </v-col></v-row
      >
      <!---->
      <v-row class="pa-3">
        <v-col lg="8" offset="2">
          <v-sheet color="white sheet" elevation="2" width="100%">
            <v-col>
              <v-list flat>
                <!--Header-->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h4 font-weight-medium mb-3">
                      Balance Sheet
                    </v-list-item-title>
                    <!--Company Name-->
                    <p class="mb-3">
                      {{ company_legal_name }}
                      <span v-if="company_trading_as"
                        >T/A {{ company_trading_as }}</span
                      >
                    </p>
                    <!--Statement Date-->
                    <p v-if="start_month && end_month">
                      For the period
                      {{
                        new Date(start_month).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                        })
                      }}
                      to
                      {{
                        new Date(end_month).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                        })
                      }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-col cols="12">
                  <!--Account Types-->
                  <v-list-item v-for="(type, i) in accountTypes" :key="i">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-h6 font-weight-medium mb-3"
                        >{{ type }}</v-list-item-title
                      >

                      <div
                        v-for="ledger in ledgerTotals.filter(
                          (x) => x.account_type === type && x.total !== 0
                        )"
                        :key="ledger.account_id"
                      >
                        <div class="mt-2">
                          <p>
                            <span>{{ ledger.account_name }}</span>
                            <span class="float-right">
                              {{ formatAsCurrency("R", ledger.total) }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import { formatAsCurrency } from "../../../composables/external";
export default {
  name: "BalanceSheet",
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      current_month: new Date().toISOString().slice(0, 7),
      start_month: null,
      end_month: null,
      months: [],
      newEntriesAlert: false,
      ledgerTotals: [],
      accountTypes: ["Assets", "Liabilities", "Equity", "VAT"],
    };
  },
  created() {
    this.getAccountingPeriods();
  },
  methods: {
    formatAsCurrency,
    // Ensures 'End Date' is never before 'Start Date
    invertDates() {
      if (this.end_month > this.start_month) return;
      [this.start_month, this.end_month] = [this.end_month, this.start_month];
    },
    //
    getAccountingPeriods() {
      const docRef = db
        .collection("accounting_totals")
        .where("id", "<=", this.current_month)
        .orderBy("id", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          this.months = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data().id;
            this.months.push({
              text: new Date(data).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
              }),
              value: data,
            });
          });
        })
        .catch((error) => error);
    },
    //
    async getLedgerAccounts(start, end) {
      this.ledgerTotals = [];
      const ledgerRef = db
        .collectionGroup("ledger_accounts")
        .where("financial_statement", "==", "Balance Sheet")
        .where("month", ">=", start)
        .where("month", "<=", end);
      try {
        const snapshot = await ledgerRef.get();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          // If ledger is in list --> calculate its total value
          // If ledger isn't in list --> add it
          if (data.account_name === "Suspense (Bank Debits)")
            console.log(data.monthly_total);
          if (this.ledgerTotals.some((x) => x.account_id === data.account_id)) {
            const index = this.ledgerTotals.findIndex(
              (x) => x.account_id === data.account_id
            );
            this.ledgerTotals[index].total += data.monthly_total;
          } else {
            this.ledgerTotals.push({ ...data, total: data.monthly_total });
          }
        });
        this.ledgerTotals = this.ledgerTotals.sort((a, b) =>
          a.account_name > b.account_name ? 1 : -1
        );
        // Insert totals for each section
        this.accountTypes.forEach((type) => {
          const index = this.ledgerTotals.findIndex(
            (el) => el.account_type === type
          );
          // Calculate total of section
          const typeTotal = this.ledgerTotals.reduce((total, item) => {
            // Ensure contra amounts are deducted from totals
            if (item.isContra) item.total = -Math.abs(item.total);
            return item.account_type === type ? total + item.total : total;
          }, 0);
          const insertItem = {
            account_name: `Total ${type}`,
            account_type: type,
            total: typeTotal,
          };
          this.ledgerTotals.splice(index, 0, insertItem);
        });
      } catch (error) {
        return new Error(error);
      }
    },
  },
};
</script>