<template>
  <div>
    <v-container fluid class="px-10">
      <v-row class="mb-3">
        <v-col lg="8" offset="2">
          <v-card-title>
            <h2>Financial Statements</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            ></v-skeleton-loader>
          </v-card-title>
        </v-col>
      </v-row>
      <!--Financial Statements links-->
      <v-row>
        <v-col lg="8" offset="2">
          <v-expansion-panels class="mb-3">
            <v-expansion-panel
              v-for="item in finacial_statements"
              :key="item.id"
            >
              <router-link :to="item.to" style="text-decoration: none">
                <v-expansion-panel-header disable-icon-rotate class="my-2">
                  {{ item.name }}
                  <template v-slot:actions>
                    <v-icon small> mdi-open-in-new </v-icon>
                  </template>
                </v-expansion-panel-header>
              </router-link>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <!--Reports-->
      <v-row class="mb-3">
        <v-col lg="8" offset="2">
          <v-card-title>
            <h2>Other Reports</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            ></v-skeleton-loader
          ></v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="8" offset="2">
          <v-expansion-panels class="mb-3">
            <v-expansion-panel v-for="item in reports" :key="item.id">
              <router-link :to="item.to" style="text-decoration: none">
                <v-expansion-panel-header disable-icon-rotate class="my-2">
                  {{ item.name }}
                  <template v-slot:actions>
                    <v-icon small> mdi-open-in-new </v-icon>
                  </template>
                </v-expansion-panel-header>
              </router-link>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "FinancialStatements",
  data() {
    return {
      finacial_statements: [
        { name: "Income Statement", to: "/income-statement" },
        { name: "Balance Sheet", to: "/balance-sheet" },
        { name: "Cashflow Statement", to: "/cashflow-statement" },
      ],
      reports: [
        { name: "Sales Report", to: "/sales-report" },
        { name: "VAT Report", to: "/income-statement" },
        { name: "Fixed Asset Register", to: "/balance-sheet" },
        { name: "Sales Per Product", to: "/sales-per-product" },
      ],
    };
  },
  methods: {},
};
</script>
