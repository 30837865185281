<template>
  <div><InvoicesTable /></div>
</template>
<script>
export default {
  name: "Invoices",
  components: {
    InvoicesTable: () => import("../components/Tables/InvoicesTable.vue"),
  },
  data() {
    return {};
  },
};
</script>
