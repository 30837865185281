<template>
  <v-dialog
    v-model="paymentDialog[invoice.invoice_id]"
    max-width="900px"
    :key="invoice.invoice_id"
  >
    <v-card>
      <!--Title-->
      <v-card-title
        >Allocate Payment
        <v-spacer></v-spacer>
        <v-btn
          right
          small
          icon
          class="float-right black--text"
          @click="
            passModalClose($event);
            resetValues();
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="px-5">
        <!--Invoice Summary-->
        <v-alert text dense v-if="!!bankAccount">
          <v-row>
            <v-col><h4>Invoice Summary</h4></v-col>
          </v-row>
          <v-row>
            <v-col lg="5" md="5">
              <!--Total-->
              <p>
                Invoice Total:
                <span class="float-right">{{
                  formatAsCurrency("R", invoice.invoice_total)
                }}</span>
              </p>
              <!--Amount Due-->
              <p>
                Amount Still Due:
                <span class="float-right">{{
                  formatAsCurrency("R", invoice.invoice_amount_due)
                }}</span>
              </p>
              <!--Customer-->
              <p>
                Customer:
                <span class="float-right">{{
                  invoice.customer.customer_name
                }}</span>
              </p>
              <!--Date-->
              <p>
                Invoice Date:
                <span class="float-right">{{
                  invoice.invoice_issue_date
                }}</span>
              </p>
            </v-col>
          </v-row>
        </v-alert>

        <!--Bank Account-->
        <v-row>
          <v-col>
            <h4 class="mb-3">Bank Account</h4>
            <v-select
              v-model="bankAccount"
              :items="bank_accounts"
              item-text="bank_account_name"
              item-value="bank_account_id"
              label="Select Bank Account"
              dense
              outlined
              color="#3d2cdd"
              @change="getBankTransactions()"
            ></v-select>
          </v-col>
        </v-row>
        <!--Transactions-->
        <v-row v-if="bankAccount">
          <v-col>
            <h4 class="mb-3">Transactions</h4>
            <v-data-table
              v-model="selectedTransactions"
              :search="search"
              :headers="headers"
              :items="filteredItems"
              dense
              show-select
              item-key="transaction_id"
              :item-class="addClass"
              :loading="loadingTransactions"
            >
              <template v-slot:top>
                <!--Search bar-->
                <v-text-field
                  v-model="search"
                  placeholder="Search..."
                  clearable
                  dense
                  outlined
                  append-icon="mdi-magnify"
                ></v-text-field>
                <!--Alert-->
                <v-row>
                  <v-col lg="4">
                    <v-row align="center">
                      <v-col class="grow"> Suggested matches </v-col>
                      <v-col class="shrink">
                        <!--Filter button>-->
                        <v-switch
                          flat
                          inset
                          v-model="suggestedMatch"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <!--Click to view bank transaction-->
              <template v-slot:item.transaction_number="{ item }">
                <a @click="passRouteData(item)">{{
                  item.transaction_number
                }}</a>
              </template>
              <!--Remove "Select All" checkbox-->
              <template v-slot:header.data-table-select> </template>
              <!--Format Amount-->
              <template v-slot:item.transaction_amount="{ item }">
                {{ formatAsCurrency("R", item.transaction_amount) }}
              </template>
              <!--Attachments-->
              <template v-slot:item.attachments="{ item }">
                <v-btn
                  small
                  icon
                  @click="openAttachment(item)"
                  v-if="item.attachments && item.attachments.length !== 0"
                  ><v-icon small>mdi-attachment</v-icon></v-btn
                >
              </template>
              <!--Empty suggested matches-->
              <template v-slot:no-data>
                <v-card class="pa-5" elevation="0">
                  There are no suggested matches for this invoice <br />
                  <v-btn @click="suggestedMatch = false" class="mt-3"
                    >Load All Transactions</v-btn
                  >
                </v-card>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!---->
        <v-card-actions v-if="bankAccount" class="mt-5">
          <v-spacer></v-spacer>
          <!--Close Button-->
          <v-btn
            text
            @click="
              passModalClose($event);
              resetValues();
            "
          >
            <v-icon left small>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn
            color="success"
            class="pr-5"
            :disabled="disableAllocate"
            :loading="loading"
            @click="allocatePaymentToInvoice()"
          >
            <v-icon left small>mdi-check</v-icon>
            Allocate Payment{{ selectedTransactions.length >= 2 ? "s" : "" }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
import {
  getBankTransactions,
  allocatePaymentToInvoice,
} from "../../data/external_invoices";
export default {
  name: "InvoicePaymentAllocationModal",
  props: ["paymentDialog", "invoice"],
  components: {},
  data() {
    return {
      selectedTransactions: [],
      bankAccount: null,
      filterItems: true,
      suggestedMatch: true,
      count: null,
      journal_entry_count: null,
      bank_accounts: [],
      transactions: [],
      loadingTransactions: false,
      payment_number: null,
      loading: false,
      search: "",
      headers: [
        { text: "Transaction #", value: "transaction_number", align: "start" },
        { text: "Date", value: "transaction_date" },
        { text: "Description", value: "transaction_description" },
        { text: "Amount", value: "transaction_amount", align: "right" },
        { text: "", value: "attachments", align: "right" },
      ],
    };
  },
  computed: {
    //  Populates suggested matches based on the weights of each item
    filteredItems() {
      return this.suggestedMatch
        ? this.transactions.filter((item) => !!item.shortcut)
        : this.transactions;
    },
    disableAllocate() {
      const transactionTotals = this.selectedTransactions.reduce(
        (total, item) => total + Math.abs(item.transaction_amount),
        0
      );
      return transactionTotals <= this.invoice.invoice_amount_due &&
        transactionTotals !== 0
        ? false
        : true;
    },
  },
  created() {
    this.getBankAccounts();
    this.getPaymentCount();
    this.getJournalEntryCount();
  },
  methods: {
    formatAsCurrency,
    getBankTransactions,
    allocatePaymentToInvoice,
    getBankAccounts() {
      const accountArray = ["bank_accounts", "speed_points", "petty_cash"];
      accountArray.forEach((account) => {
        const docRef = db
          .collection(account)
          .where("bank_account_status", "==", "Active");
        docRef
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              this.bank_accounts.push(data);
            });
          })
          .catch((error) => error);
      });
    },
    //
    getPaymentCount() {
      const docRef = db.doc("customer_payment_counter/uDlDYQCHnCW03mhjtGla");
      docRef.onSnapshot((doc) => {
        this.count = doc.data().count;
      });
    },
    //
    getJournalEntryCount() {
      db.collection("general_journal_entry_counter")
        .doc("LVEzU1bhljJ8ZLLz6pC2")
        .onSnapshot((snapshot) => {
          this.journal_entry_count = snapshot.data().journal_entry_count;
        });
    },
    //
    addClass(item) {
      //  Highlights the table row of items that have the greatest weight
      //  Weights are determined by items that match the "recommendation criteria" above
      const filteredItems = this.transactions.filter((item) => !!item.shortcut);
      const weightEval = filteredItems.reduce(
        (acc, el) => (acc = acc > el.weight ? acc : el.weight),
        0
      );
      // Don't apply a class if all the array values have equal weights
      if (filteredItems.every((el) => el.weight === weightEval)) return "";
      return item.weight === weightEval ? "green lighten-4" : "";
    },
    //
    passModalClose() {
      this.$set(this.paymentDialog, this.invoice.invoice_id, false);
    },
    resetValues() {
      this.selectedTransactions = [];
      this.bankAccount = null;
      this.transactions = [];
    },
    //
    formatPaymentNumber() {
      return this.count < 1000
        ? `CPA-${String("00000" + this.count).slice(-4)}`
        : `CPA-${this.count}`;
    },
    formatJournalEntryNumber() {
      return this.journal_entry_count < 1000
        ? `GJE-${String("00000" + this.journal_entry_count).slice(-4)}`
        : `GJE-${this.journal_entry_count}`;
    },
    incrementPaymentCount() {
      const docRef = db.doc("customer_payment_counter/uDlDYQCHnCW03mhjtGla");
      docRef.update({ count: this.count });
    },
    incrementJournalEntryCount() {
      const docRef = db.doc(
        "general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2"
      );
      docRef.update({ journal_entry_count: this.journal_entry_count });
    },
    // Click to view bank transaction in new tab
    passRouteData(item) {
      const routeData = this.$router.resolve({
        name: "bank-statements",
        query: {
          search: item.transaction_number,
          start_date: item.transaction_date,
        },
      });
      window.open(routeData.href, "_blank");
    },
    //
    openAttachment(item) {
      window.open(item.attachments[0].src, "_blank");
    },
  },
};
</script>
