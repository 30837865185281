<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Journal Entries</h2>
            <!--Add Journal Entry Button-->
            <v-dialog v-model="dialog" width="950" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="2"
                  color="#33cc99"
                  class="white--text ml-10"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Add Journal Entry
                </v-btn>
              </template>
              <AddJournalEntryModal @closeDialog="dialog = false" />
            </v-dialog>
          </v-card-title>
        </v-col>
      </v-row>
      <!--Table-->
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <v-data-table
              :headers="journalEntriesHeaders"
              :items="filteredJournalEntries"
              loading-text="Loading entries... Please wait"
              :items-per-page="100"
              :sort-by="['journal_entry_number']"
              :sort-desc="[false]"
              multi-sort
              dense
              :search="search"
            >
              <!--Filters-->
              <template v-slot:top>
                <v-container>
                  <v-row>
                    <v-col md="3">
                      <!--Search-->
                      <v-text-field
                        v-model="search"
                        placeholder="Search..."
                        dense
                        outlined
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-col>
                    <!--Start Date range-->
                    <v-col md="2">
                      <v-menu
                        ref="start_date_menu"
                        v-model="start_date_menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="start_date"
                            label="Start Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            color="#3d2cdd"
                            clearable
                            @click:clear="clearStartDate()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          :max="end_date"
                          @change="assignStartDate()"
                          title="End Date"
                          color="#3d2cdd"
                          no-title
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#ce2458"
                            @click="start_date_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#33cc99"
                            @click="$refs.start_date_menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <!--End Date range-->
                    <v-col md="2">
                      <v-menu
                        ref="end_date_menu"
                        v-model="end_date_menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="end_date"
                            label="End Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            color="#3d2cdd"
                            clearable
                            @click:clear="clearEndDate()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="end_date"
                          :min="start_date"
                          @change="assignEndDate()"
                          title="End Date"
                          color="#3d2cdd"
                          no-title
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#ce2458"
                            @click="end_date_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#33cc99"
                            @click="$refs.end_date_menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <!--Reconciled Status-->
                    <v-col md="2">
                      <v-select
                        v-model="reconciled"
                        outlined
                        dense
                        label="Reconciled"
                        :items="[
                          { name: 'Yes', value: true },
                          { name: 'No', value: false },
                        ]"
                        item-text="name"
                        item-value="value"
                      ></v-select>
                    </v-col>
                    <!--Ledger Filter-->
                    <v-col md="3">
                      <v-autocomplete
                        @click="getChartOfAccounts()"
                        :loading="loading_ledgers"
                        item-text="account_name"
                        item-value="account_id"
                        outlined
                        dense
                        v-model="ledger_account"
                        label="Ledger Filter"
                        placeholder="Select Ledger Account..."
                        append-icon="mdi-filter-variant"
                        :items="chart_of_accounts"
                        clearable
                        @click:clear="clearLedgerAccount()"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <!--End of filters -->

              <!--Reconciled status-->
              <template v-slot:item.reconciled="{ item }">
                <a v-if="item.bank_transaction" @click="passRouteData(item)">{{
                  item.bank_transaction.transaction_number
                }}</a>
                <span
                  v-else-if="
                    item.line_items.some((x) =>
                      x.ledger_account.account_name.includes(
                        'Contra' || 'Revenue'
                      )
                    )
                  "
                  ><a>Automatic Entry</a></span
                >
                <span v-else>No</span>
              </template>
              <!-- Line Items -->
              <template v-slot:item.line_items="{ item }">
                <v-list
                  dense
                  v-for="el in item.line_items"
                  :key="el.ledger_account.account_id"
                >
                  <span dense v-if="el.entry_amount !== 0">
                    {{ el.ledger_account.account_name }} --
                    {{ formatAsCurrency("R", el.entry_amount) }}
                  </span>
                </v-list>
              </template>
              <!--Actions-->
              <template v-slot:item.journal_entry_id="props">
                <!--Menu items-->
                <v-menu top :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on"
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(el, index) in action_items"
                      :key="index"
                      :disabled="!!el.disabled && el.disabled(props.item)"
                      class="change-cursor"
                    >
                      <v-list-item-title @click="el.action(props.item)"
                        ><v-icon
                          left
                          small
                          :disabled="!!el.disabled && el.disabled(props.item)"
                          >{{ el.icon }}</v-icon
                        >{{ el.name }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <ReconcileJournalEntryModal
                  :dialog="reconcileDialog"
                  :entry="props.item"
                  @closeDialog="
                    $set(reconcileDialog, props.item.journal_entry_id, false)
                  "
                />
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { formatAsCurrency } from "../../../composables/external";
import { showSnackbar } from "../../../globalActions";
export default {
  name: "JournalEntries",
  components: {
    AddJournalEntryModal: () =>
      import("../components/Modals/AddJournalEntryModal.vue"),
    ReconcileJournalEntryModal: () =>
      import("../components/Modals/ReconcileJournalEntryModal.vue"),
  },
  data() {
    return {
      entries: [],
      loading_entries: false,
      search: "",
      date: null,
      dateFilter: ["2020-10-01", new Date().toISOString().slice(0, 10)],
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      ledger_account: "",
      reconciled: "",
      chart_of_accounts: [],
      loading_ledgers: false,
      dialog: false,
      reconcileDialog: {},
      journalEntriesHeaders: [
        {
          text: "Journal Entry Number",
          value: "journal_entry_number",
          align: "start",
        },
        {
          text: "Entry Date",
          value: "journal_entry_date",
        },
        {
          text: "Reconciled",
          value: "reconciled",
        },
        {
          text: "Line Items",
          value: "line_items",
          align: "right",
        },
        {
          text: "Actions",
          value: "journal_entry_id",
          align: "right",
        },
      ],
      action_items: [
        {
          name: "Delete Entry",
          icon: "mdi-delete",
          disabled: (entry) => (entry.reconciled ? true : false),
          action: (entry) => this.deleteEntry(entry),
        },
        {
          name: "Allocate To Bank Transaction",
          icon: "mdi-bank",
          disabled: (entry) => (entry.reconciled ? true : false),
          action: (entry) =>
            this.$set(this.reconcileDialog, entry.journal_entry_id, true),
        },
      ],
    };
  },
  computed: {
    // Journal Entry Filters
    filteredJournalEntries() {
      //  Create array of filter functions
      const filters = {
        dateRange: (item) =>
          item.journal_entry_date >= this.dateFilter[0] &&
          item.journal_entry_date <= this.dateFilter[1],
        ledgerAccount: (item) =>
          item.line_items.some(
            (x) =>
              x.ledger_account.account_id === this.ledger_account &&
              x.entry_amount !== 0
          ),
        reconciledStatus: (item) => item.reconciled === this.reconciled,
      };
      const selected = [filters.dateRange];
      //  Set conditions to apply filters to the array
      this.ledger_account !== "" ? selected.push(filters.ledgerAccount) : "";
      this.reconciled !== "" ? selected.push(filters.reconciledStatus) : "";
      const output = this.entries.filter((item) =>
        selected.every((filter) => filter(item))
      );
      return output;
    },
  },
  created() {
    this.getJournalEntries();
  },
  methods: {
    //
    returnDisabled(entry) {
      return entry.reconciled ? true : false;
    },
    formatAsCurrency,
    //
    getJournalEntries() {
      const docRef = db
        .collection("general_journal_entries")
        .orderBy("journal_entry_number");
      docRef.onSnapshot((snapshot) => {
        this.entries = [];
        this.entries = snapshot.docs.map((doc) => {
          // If assigned to bank transaction
          // If Auatomatic entry (contra or revenue)
          const entry = doc.data();
          let reconciled;
          const query =
            entry.line_items.some((x) =>
              x.ledger_account.account_name.includes("Contra")
            ) ||
            entry.line_items.some((x) =>
              x.ledger_account.account_name.includes("Revenue")
            ) ||
            !!entry.bank_transaction;
          reconciled = query ? true : false;
          return { ...entry, reconciled };
        });
      });
    },
    // Populate chart of accounts for ledger filter
    async getChartOfAccounts() {
      const docRef = db.collection("chart_of_accounts").orderBy("account_name");
      if (this.chart_of_accounts.length !== 0) return;
      this.loading_ledgers = true;
      try {
        const snapshot = await docRef.get();
        this.chart_of_accounts = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        this.loading_ledgers = false;
      } catch (error) {
        return new Error(error);
      }
    },
    //
    // Click to view bank transaction in new tab
    async passRouteData(item) {
      // Find bank transaction date
      const bankRef = db
        .collection("bank_transactions")
        .where(
          "transaction_number",
          "==",
          item.bank_transaction.transaction_number
        );
      try {
        const snapshot = await bankRef.get();
        const startDate = snapshot.docs.map((doc) => ({ ...doc.data() }))[0]
          .transaction_date;
        console.log(startDate);
        const routeData = this.$router.resolve({
          name: "bank-statements",
          query: {
            search: item.bank_transaction.transaction_number,
            start_date: startDate,
          },
        });
        window.open(routeData.href, "_blank");
      } catch (error) {
        console.log(error);
      }
    },
    //
    assignStartDate() {
      this.dateFilter.splice(0, 1, this.start_date);
      if (this.end_date < this.start_date) this.dateFilter.reverse();
    },
    assignEndDate() {
      this.dateFilter.splice(1, 1, this.end_date);
      if (this.end_date < this.start_date) this.dateFilter.reverse();
    },
    // Delete Journal Entry
    async deleteEntry(entry) {
      if (!confirm("Are you sure you want to delete this entry")) return;
      const batch = db.batch();
      const entryRef = db.doc(
        `general_journal_entries/${entry.journal_entry_id}`
      );
      try {
        // 1 Remove entry from database
        batch.delete(entryRef);
        // 2 Remove line amounts from accounting totals
        entry.line_items.forEach((item) => {
          const ledgerRef = db.doc(
            `accounting_totals/${entry.journal_entry_date.slice(
              0,
              7
            )}/ledger_accounts/${item.ledger_account.account_id}`
          );
          batch.update(ledgerRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              -Math.abs(item.entry_amount)
            ),
          });
        });
        // 3 Show success message
        showSnackbar("Entry deleted successfully!");
        await batch.commit();
      } catch (error) {
        return new Error(error);
      }
    },
  },
};
</script>

<style scoped>
.change-cursor:hover {
  cursor: pointer;
}
</style>