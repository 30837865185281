import { Line } from 'vue-chartjs'
import db from "../../../components/firebaseInit";

export default {
  name: "SalesReport",
  extends: Line,
  data() {
    return {
      dateRange: [],
      salesOrders: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: true,
            borderColor: "#3dcb9a",
            backgroundColor: "rgba(173,224,203,0.5)",
            borderWidth: 3,
            tension: 0.2,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.constructChart()
  },
  methods: {

    constructChart() {
      const promises = [];
      //  X Axis
      const getDateRange = () => {
        const dateRef = db.collection("accounting_totals").where("id", ">=", "2020-07");

        promises.push(
          dateRef.get().then((querySnapshot) => {
            querySnapshot
              .forEach((doc) => {
                const data = doc.data().id;
                this.dateRange.push(data)
                this.chartData.labels.push(data);
              });
          })
            .catch((error) => error)
        );
      }
      //Get Sales from DB
      const getSalesOrders = () => {
        const docRef = db.collection("sales_orders")
        promises.push(
          docRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              this.salesOrders.push(data)
            })
          }).then(() => calcOrdersPerMonth())
            .catch((error) => error)
        );
      }
      // Y Axis
      // Calc Orders per month
      const calcOrdersPerMonth = () => {
        this.dateRange.forEach((month) => {
          const ordersPerMonth = this.salesOrders.filter((order) => order.order_date.slice(0, 7) === month && (order.order_status === "Confirmed" || order.order_status === "Completed"))
          const valuePerMonth = ordersPerMonth.reduce((total, item) => total + item.order_value, 0)
          if (valuePerMonth === 0) return;
          this.chartData.datasets[0].data.push(valuePerMonth / 1000)
        })
      }

      getDateRange();
      getSalesOrders();

      promises.reduce((accumulatorPromise, nextPromise) => {
        return accumulatorPromise.then(() => {
          return nextPromise;
        });
      }, Promise.resolve())
        .then(() => this.renderChart(this.chartData, this.options));
      //getSalesPerMonth();
    },
  },
}