var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',[_vm._v("Journal Entries")]),_c('v-dialog',{attrs:{"width":"950","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-10",attrs:{"elevation":"2","color":"#33cc99"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Journal Entry ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AddJournalEntryModal',{on:{"closeDialog":function($event){_vm.dialog = false}}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.journalEntriesHeaders,"items":_vm.filteredJournalEntries,"loading-text":"Loading entries... Please wait","items-per-page":100,"sort-by":['journal_entry_number'],"sort-desc":[false],"multi-sort":"","dense":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"placeholder":"Search...","dense":"","outlined":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-menu',{ref:"start_date_menu",attrs:{"close-on-content-click":true,"return-value":_vm.date,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.start_date,"label":"Start Date","append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","color":"#3d2cdd","clearable":""},on:{"click:clear":function($event){return _vm.clearStartDate()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_date_menu),callback:function ($$v) {_vm.start_date_menu=$$v},expression:"start_date_menu"}},[_c('v-date-picker',{attrs:{"max":_vm.end_date,"title":"End Date","color":"#3d2cdd","no-title":""},on:{"change":function($event){return _vm.assignStartDate()}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#ce2458"},on:{"click":function($event){_vm.start_date_menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#33cc99"},on:{"click":function($event){return _vm.$refs.start_date_menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-menu',{ref:"end_date_menu",attrs:{"close-on-content-click":true,"return-value":_vm.date,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.end_date,"label":"End Date","append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","color":"#3d2cdd","clearable":""},on:{"click:clear":function($event){return _vm.clearEndDate()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_date_menu),callback:function ($$v) {_vm.end_date_menu=$$v},expression:"end_date_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.start_date,"title":"End Date","color":"#3d2cdd","no-title":""},on:{"change":function($event){return _vm.assignEndDate()}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#ce2458"},on:{"click":function($event){_vm.end_date_menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#33cc99"},on:{"click":function($event){return _vm.$refs.end_date_menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Reconciled","items":[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false } ],"item-text":"name","item-value":"value"},model:{value:(_vm.reconciled),callback:function ($$v) {_vm.reconciled=$$v},expression:"reconciled"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loading_ledgers,"item-text":"account_name","item-value":"account_id","outlined":"","dense":"","label":"Ledger Filter","placeholder":"Select Ledger Account...","append-icon":"mdi-filter-variant","items":_vm.chart_of_accounts,"clearable":""},on:{"click":function($event){return _vm.getChartOfAccounts()},"click:clear":function($event){return _vm.clearLedgerAccount()}},model:{value:(_vm.ledger_account),callback:function ($$v) {_vm.ledger_account=$$v},expression:"ledger_account"}})],1)],1)],1)]},proxy:true},{key:"item.reconciled",fn:function(ref){
                      var item = ref.item;
return [(item.bank_transaction)?_c('a',{on:{"click":function($event){return _vm.passRouteData(item)}}},[_vm._v(_vm._s(item.bank_transaction.transaction_number))]):(
                  item.line_items.some(function (x) { return x.ledger_account.account_name.includes(
                      'Contra' || 'Revenue'
                    ); }
                  )
                )?_c('span',[_c('a',[_vm._v("Automatic Entry")])]):_c('span',[_vm._v("No")])]}},{key:"item.line_items",fn:function(ref){
                var item = ref.item;
return _vm._l((item.line_items),function(el){return _c('v-list',{key:el.ledger_account.account_id,attrs:{"dense":""}},[(el.entry_amount !== 0)?_c('span',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(el.ledger_account.account_name)+" -- "+_vm._s(_vm.formatAsCurrency("R", el.entry_amount))+" ")]):_vm._e()])})}},{key:"item.journal_entry_id",fn:function(props){return [_c('v-menu',{attrs:{"top":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.action_items),function(el,index){return _c('v-list-item',{key:index,staticClass:"change-cursor",attrs:{"disabled":!!el.disabled && el.disabled(props.item)}},[_c('v-list-item-title',{on:{"click":function($event){return el.action(props.item)}}},[_c('v-icon',{attrs:{"left":"","small":"","disabled":!!el.disabled && el.disabled(props.item)}},[_vm._v(_vm._s(el.icon))]),_vm._v(_vm._s(el.name))],1)],1)}),1)],1),_c('ReconcileJournalEntryModal',{attrs:{"dialog":_vm.reconcileDialog,"entry":props.item},on:{"closeDialog":function($event){return _vm.$set(_vm.reconcileDialog, props.item.journal_entry_id, false)}}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }