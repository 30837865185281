<template>
  <v-container>
    <v-row class="mb-3">
      <v-col lg="12" md="12">
        <v-card-title>
          <h2>Journal Reports</h2>
          <!--Skeleton button-->
          <v-skeleton-loader
            :loading="loading_user"
            type="button"
            v-if="firstLoad"
            class="ml-10"
          >
          </v-skeleton-loader>
          <AddJournalEntryButton
            v-if="!firstLoad"
            v-show="this.user_roles_list.flat().includes('accounting_write')"
          />
        </v-card-title>
        <JournalEntryFilters
          @changeJournal="changeJournal($event)"
          :filtered_entries="filtered_entries"
          :fiscal_year_start="fiscal_year_start"
          :first_fiscal_year="first_fiscal_year"
          :filteredDate="filteredDate"
          @changeFinancialPeriod="changeFinancialPeriod($event)"
          @changeSearch="changeSearch($event)"
          @clearSearch="changeSearch($event)"
        />
      </v-col>
    </v-row>
    <!--Table skeleton loader-->
    <v-row v-if="firstLoad">
      <v-col>
        <v-sheet elevation="4">
          <v-skeleton-loader
            :loading="loading_user"
            type="table"
            max-height="500"
          >
          </v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>
    <!--End of skeleton loader-->
    <div
      v-if="!firstLoad"
      v-show="this.user_roles_list.flat().includes('accounting_read')"
    ></div>
    <v-sheet elevation="4">
      <v-row>
        <v-col class="ml-5">
          <h3>{{ this.account_name }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="journalReportHeaders"
            :items="filteredDate"
            :search="search"
            :sort-by="['journal_entry_date', 'journal_entry_number']"
            :sort-desc="true"
            multi-sort
            :items-per-page="1000"
            group-by="journal_entry_date"
            :group-desc="true"
            dense
          >
            <!--Entry Date-->
            <template v-slot:group.header="{ items }">
              <th colspan="9" class="blue-grey lighten-5">
                {{
                  new Date(items[0].journal_entry_date).toLocaleDateString(
                    "en-GB",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                }}
              </th>
            </template>
            <!--Format Amounts-->
            <template v-slot:item.entry_amount="{ item }">
              {{ formatAsCurrency("R", item.entry_amount) }}
            </template>
            <!--Format Allocation status-->
            <template v-slot:item.allocation_status="{ item }">
              <span v-if="item.allocation_status">{{
                item.allocation_status
              }}</span>
              <span v-else>No</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <!--End of datatable-->
  </v-container>
</template>
<script>
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { formatAsCurrency } from "../../../composables/external";
import { getJournalEntries } from "../data/external_journal_entries";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
export default {
  components: {
    AddJournalEntryButton: () =>
      import("../components/journal_entries/AddJournalEntryButton.vue"),
    JournalEntryFilters: () =>
      import("../components/journal_entries/JournalEntryFilters.vue"),
  },
  mixins: [mixin_UserRoles, mixin_CompanyProfile],
  data() {
    return {
      search: "",
      account_name: null,
      account_id: null,
      journalReportHeaders: [
        { text: "Entry #", value: "journal_entry_number", align: "start" },
        { text: "Date", value: "journal_entry_date" },
        {
          text: "Ledger Account",
          value: "account_name",
        },
        { text: "Allocated", value: "allocation_status" },
        { text: "Entry Amount", value: "entry_amount", align: "right" },
      ],
      journal_entries: [],
      filtered_entries: [],
      financial_period: null,
      dateFilter: [],
    };
  },
  computed: {
    // Evaluates the journal entry date against a selected financial period
    filteredDate() {
      if (!this.financial_period) return;

      return this.filtered_entries.filter((i) => {
        return (
          this.dateFilter === [] ||
          (i.journal_entry_date.slice(0, -3) >=
            this.financial_period.split(" - ")[0] &&
            i.journal_entry_date.slice(0, -3) <=
              this.financial_period.split(" - ")[1])
        );
      });
    },
  },
  methods: {
    formatAsCurrency,
    changeJournal(account_name) {
      this.account_name = account_name.account_name;
      this.account_id = account_name.account_id;
      this.getJournalEntries(this.account_id);
    },
    changeFinancialPeriod(financial_period) {
      this.financial_period = financial_period;
    },
    changeSearch(search) {
      this.search = search;
    },
    getJournalEntries,
  },
};
</script>
