<template>
  <CreditNoteTable />
</template>
<script>
export default {
  name: "CustomerCreditNotes",
  components: {
    CreditNoteTable: () => import("../components/Tables/CreditNoteTable.vue"),
  },
  data() {
    return {};
  },
};
</script>