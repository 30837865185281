<template>
  <v-container>
    <v-row class="mb-5">
      <v-col>
        <h1>Sales Report</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <chart-line />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChartLine from "../components/ChartLine";

export default {
  name: "app",
  components: {
    ChartLine,
  },
};
</script>